import React, {JSXElementConstructor} from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import reportWebVitals from './reportWebVitals';

// store
import { persistor, store } from './state/store';

// components
import App from './App';

// hooks providers
import { SettingsContextProvider } from './hooks/useSettings';
import { QuestionnaireContextProvider } from './hooks/useQuestionnaire';
import { QuestionContextProvider } from './hooks/useQuestion';

// styles
import './index.scss';
import { PermissionContextProvider } from "./hooks/usePermissionsHook";

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const renderApp = (Component: JSXElementConstructor<any>) => {
  render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SettingsContextProvider>
          <QuestionnaireContextProvider>
            <QuestionContextProvider>
              <PermissionContextProvider>
                <Component />
              </PermissionContextProvider>
            </QuestionContextProvider>
          </QuestionnaireContextProvider>
        </SettingsContextProvider>
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
};

renderApp(App);
